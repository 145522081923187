<template>
  <v-card id="cw-confirmation-dialog--dialog__signee-removal">
    <v-card-title id="cw-confirmation-dialog__title" class="text-h5">
      <translate>
        Delete signee
      </translate>
    </v-card-title>
    <v-card-text id="cw-confirmation-dialog__text">
      <p
        v-translate="{
          openingTag: '<b>',
          closingTag: '</b>',
          name: item.name,
        }"
        render-html="true"
      >
        %{ openingTag }%{ name }%{ closingTag } will be removed from the signatories added
        to the application.
      </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        id="cw-confirmation-dialog__cancel"
        color="primary"
        text
        @click="
          $emit('dialog-cancel');
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Cancel
        </translate>
      </v-btn>
      <v-btn
        id="cw-confirmation-dialog__confirm"
        :loading="$wait.waiting('confirmation_dialog__confirm_processing')"
        :disabled="$wait.waiting('confirmation_dialog__confirm_processing')"
        color="primary"
        text
        @click="
          onConfirm();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Delete
        </translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'CwDialogSigneeRemoval',

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    onConfirm() {
      this.$wait.start('confirmation_dialog__confirm_processing');
      this.$bus.$emit('SIGNEES_CONFIRM_REMOVAL', { id: this.item.ssn, type: 'signee' });
    },
  },
};
</script>
